<template>
  <!-- 经感主页 -->
  <div id="app" style="background: #f4f4f4">
    <div class="expression-banner" style="margin-bottom: 12px">
      <div class="company-banner-content">
        <div class="company-banner-title">
          <div class="company-banner-logo">
            <img src="../../assets/company/mj-logo.png" />
          </div>
          <div>经感</div>
        </div>
        <div class="company-banner-text">
          北美留学，找工求职，海外生活的经验感悟
        </div>
      </div>
    </div>
    <div class="cson-container">
      <div class="company-warp" ref="companyWrap">
        <div class="clearfix">
          <div class="ms-content">
            <div class="ms-cells">
              <div
                class="ms-cell-interview"
                v-for="(exp, index) in expArray"
                :key="index"
              >
                <div
                  @click="viewExps(exp.id, exp.postsId)"
                  style="cursor: pointer"
                >
                  <div class="ms-cell-hd">
                    <div class="ms-user">
                      <div class="ms-user-avatar-s" v-if="exp.avatar">
                        <img :src="exp.avatar" />
                      </div>
                      <div class="ms-user-avatar-s" v-else>
                        <i class="el-icon-user-solid"></i>
                      </div>
                      <div class="ms-user-name" v-if="exp.username">
                        {{ exp.username }}
                      </div>
                      <div class="ms-user-name" v-else>匿名用户</div>
                      <div class="ms-user-time">发表于{{ exp.createTime }}</div>
                    </div>
                  </div>
                  <div class="ms-cell-tt">
                    {{ exp.title }}
                  </div>
                  <div class="ms-cell-ft">
                    <div
                      class="ms-cell-desc renderContent"
                      v-html="exp.content"
                    ></div>
                    <div class="ms-cell-data">
                      <el-button type="text" size="mini"
                        ><span
                          class="iconfont icon-liulan"
                          style="margin-right: 4px"
                        ></span
                        >{{ exp.pageViews }}</el-button
                      >
                      <el-button type="text" size="mini"
                        ><span
                          class="iconfont icon-dianzan"
                          style="margin-right: 4px"
                        ></span
                        >{{ exp.likeNums }}</el-button
                      >
                      <el-button type="text" size="mini"
                        ><span
                          class="iconfont icon-huifu"
                          style="margin-right: 6px"
                        ></span
                        >{{ exp.commentNums }}</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="pagination-block">
                <el-pagination
                  v-if="expTotal > 0"
                  background
                  prev-text="< 上一页"
                  next-text="下一页 >"
                  layout="prev, pager, next "
                  :total="expTotal"
                  :current-page.sync="expCurrent"
                  @current-change="(page) => getExp(page, true)"
                  :page-size="20"
                >
                </el-pagination>
              </div>
              <div
                v-if="expTotal == 0"
                style="text-align: center; font-size: 20px; padding: 30px 0"
              >
                暂无经感
              </div>
            </div>
          </div>
          <div class="ms-aside">
            <div class="ms-release" @click="handClick">
              <el-button type="text" icon="el-icon-edit-outline"
                >发经感</el-button
              >
            </div>
            <SideAdvertisement></SideAdvertisement>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getExpressions, viewExps } from "@/service/interviewExpression";
import _ from "lodash";
import { mapState } from "vuex";
import SideAdvertisement from "@/components/sideAdvertisement";
export default {
  name: "interviewExpression",
  components: { SideAdvertisement },
  data() {
    return {
      expTotal: 0,
      expCurrent: 1,
      expArray: [],
      timeout: null,
    };
  },
  methods: {
    handClick() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return this.$message.error("请先登录");
      }
      this.$router.push(`/editExpression`);
    },
    getExpressions(page) {
      getExpressions(page ? page : 1).then((res) => {
        console.log(res);
        if (res.success) {
          this.expTotal = res.result.total;
          this.expArray = res.result.records;
          this.$nextTick(() => {
            const renderArray = document.querySelectorAll(".renderContent");
            for (let i = 0; i < renderArray.length; i++) {
              let picArray = renderArray[i].querySelectorAll("img");
              for (let j = 0; j < picArray.length; j++) {
                let newNode = document.createTextNode("[图片]");
                console.log(picArray[j]);
                picArray[j].parentNode.replaceChild(newNode, picArray[j]);
              }
            }
          });
        }
      });
    },
    viewExps(id, postsId) {
      viewExps(postsId).then((res) => {
        if (res.success) {
          this.$router.push(`/interviewExpDetail?id=${id}`);
        }
      });
    },
  },
  mounted() {
    this.getExpressions();
  },
  watch: {
    $route() {
      this.getExpressions();
      window.scrollTo(0, 0);
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>
<style scoped>
@import url("../interview/interviewHome.css");
::v-deep .el-tabs__header {
  line-height: 48px;
  background-color: #fff !important;
  color: #34495e;
  font-size: 16px;
  margin-bottom: 12px;
}
::v-deep .el-tabs__nav-scroll {
  padding: 0 12px;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: transparent;
}
::v-deep .el-tabs__active-bar {
  background-color: #fa6400;
  border-radius: 2px;
  height: 4px;
  min-width: 28px;
}
::v-deep .el-tabs__item:hover,
::v-deep .el-tabs__item.is-active {
  color: #34495e;
}
.exp-tabs.inner-tabs ::v-deep .el-tabs__item {
  padding: 0 20px !important;
  height: 48px !important;
  box-sizing: border-box !important;
  line-height: 48px !important;
  display: inline-block !important;
  list-style: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #34495e !important;
  position: relative !important;
  flex: none !important;
  margin-right: 0 !important;
}
.exp-tabs.inner-tabs ::v-deep .el-tabs__item.is-active {
  border-color: transparent !important;
  background-color: transparent !important;
}

.exp-tabs.inner-tabs ::v-deep .el-tabs__nav {
  float: left !important;
  display: block !important;
}
.filterTab {
  padding: 16px 24px;
  background: #fff;
  margin-bottom: 8px;
}
.filterTab .el-dropdown {
  margin: 0 6px;
  margin-right: 24px;
}
::v-deep .el-dialog__body {
  padding-bottom: 0;
}
.aside-course:hover {
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
}
.ms-release {
  cursor: pointer;
}
.ms-release:hover {
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
}
</style>